body {
  color: #f1f1f1;
  background: #0f0f0f;
  padding: 16px;
}

a {
  text-decoration: none;
  color: #f1f1f1;
}
